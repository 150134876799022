import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/packages/recipes/src/layouts/recipe.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h5>{`Prep time: 30 min. `}<meta itemProp="prepTime" content="PT30M" /></h5>
    <h5>{`Cook time: 30 min. `}<meta itemProp="cookTime" content="PT30M" /></h5>
    <h5>{`Servings: `}<span itemProp="recipeYield">{`6-8`}</span></h5>
    <hr></hr>
    <h2>{`Ingredients`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Eggs, 12</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Mushrooms, 1 large or 2 small diced</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">White vinegar, 2 tsp.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Mayonnaise, 2 tbs.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Stone ground mustard, 1 tsp.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Cayenne, to taste</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Salt and pepper, to taste</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Paprika, 1 tsp.</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Equipment`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="tool">Large pot</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Small mixing bowl</span>
      </li>
      <li parentName="ul">
        <span itemProp="tool">Serving platter</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Prep`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="step">Dice mushrooms</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Instructions`}</h2>
    <span itemProp="recipeInstructions">
      <ol>
        <li parentName="ol">
          <p parentName="li">{`Boil eggs until hard boiled, about 10 minutes.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Drain eggs and cool in cold water.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Peel eggs.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Cut eggs lengthwise, scooping the yolks into small bowl.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Mash yolks.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Mix in vinegar, mayo, mustard, cayenne, salt, and pepper. If the mixture looks too dry, add additional mayo.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Mix in mushrooms.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Scoop egg mixture back into the egg whites.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Sprinkle with paprika.`}</p>
        </li>
      </ol>
    </span>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      